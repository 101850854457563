import React from 'react';
import { Navigator } from './Navigator';

import { ArrowLongRightIcon } from '@heroicons/react/20/solid';

const MainButton = ({ recordId, children, className }) => (
    
    <Navigator recordId={recordId}>
        <button className={className}>
            {children} <ArrowLongRightIcon className="btn_wht_icon" />
        </button>
    </Navigator>
    );
export default MainButton;
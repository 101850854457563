import React from 'react';

export const HeroSimple = ({
    title,
    span1,
    subtitle,
    button,
}) => (
    <div className="HeroWrapperSimple">
        <div className='HeroInnerSimple'>
            <h1 className="HeroTitleSimple">
                {title}
                <div className='HeroWordsWrapper'>
                <div className='HeroWords'>
                    <span className='HeroSpanSimple'>{span1}</span>
                </div>
                </div>
            </h1>
            <h2 className="HeroSubtitle">{subtitle}</h2>
            <div>{button}</div>
        </div>
    </div>
);
import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { PageWrapper } from '../components/PageWrapper';
import { HeroSimple } from '../components/HeroSimple';
import MainButton from '../components/Button';
import FooterCta from '../components/FooterCta';

const CatalogsTemplate = ({
    data: {
        datoCmsCatalogspage: {
            seo,
            title,
            subtitle,
        },
        allDatoCmsCatalog: { catalogNodes },
        datoCmsContactpage: { 'id': contactPageId},
    },
    pageContext,
}) => (
    <PageWrapper
        pageData={pageContext}
        seoTitle={seo?.seoTitle}
        seoDescription={seo?.seoDescription}
        seoImage={seo?.seoImage?.seoImageUrl}
        seo
    >   
        <div className='CatalogspageNavBackground'></div>
        <div className='CatalogspageWrapper'>
            <div className='CatalogspageContainerTitle'>
                <h1 className='CatalogspageTitle'>{title}</h1>
                <p className='CatalogspageSubtitle'>{subtitle}</p>
                <MainButton recordId={contactPageId} className={'btn_wht'}>
                        Get in Touch
                </MainButton>
            </div>
            <div className='CatalogspageContainer'>
                <div className='CatalogspageUl'>
                    {catalogNodes.map(({ id, catalogLink, catalogThumbnail, catalogCategory }) => (
                        <div key={id}>
                            <a href={catalogLink} target='_blank'>
                            <GatsbyImage image={catalogThumbnail.gatsbyImageData} alt={catalogThumbnail.alt} className="CatalogspageThumbnail"/>
                            </a>
                            <div className='CatalogCategoryContainer'>
                                <a className='CatalogCategory'>#{catalogCategory.name}</a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
        <FooterCta />
        
    </PageWrapper>
);

export default CatalogsTemplate;

export const query = graphql`
    query CatalogsQuery($locale: String!) {
        allDatoCmsCatalog(
            filter: {
                locale: { eq: $locale }
            }
        ) {
        catalogNodes: nodes {
            locale
            id: originalId
            catalogTitle
            catalogCategory {
                name
            }
            catalogLink
            catalogThumbnail {
                gatsbyImageData(
                    placeholder: BLURRED
                    forceBlurhash: false
                    imgixParams: { 
                        fit: "crop"
                        w: "400"
                        h: "565,6"
                    }
                )
            }
        }}
        datoCmsCatalogspage(locale: { eq: $locale }) {
            locale
            seo {
                seoTitle: title
                seoDescription: description
                seoImage: image {
                    seoImageUrl: url
                }
            }
            title
            subtitle
        }
        datoCmsContactpage(locale: { eq: $locale }) {
            id: originalId
        }   
}
`;
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import MainButton from './Button';

export default function FooterCta() {
    const data = useStaticQuery(graphql`
        query FooterCtaQuery {
            datoCmsFooterCta {
                cta
                cta2
            }
            datoCmsContactpage {
              id: originalId
            }
        }
    `)   
      
    return (
      <div className='FooterCtaWrapper'>
        <div className='FooterCtaInner'>
          <text className='FooterCtaText'>{data.datoCmsFooterCta.cta}</text>
          <br/>
          <text className='FooterCtaText2'>{data.datoCmsFooterCta.cta2}</text>
          <div className='FooterCtaBtn'>
            <MainButton recordId={data.datoCmsContactpage.id} className={'btn_wht'}>
              Get in Touch
            </MainButton>
          </div>
        </div>
      </div>
    )
  };

